<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-combobox
          v-model="team"
          :items="teams"
          item-text="name"
          label="Team"
        ></v-combobox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [RouteMixin],
  data () {
    return {
      team: null
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    teams () {
      return this.tournament.activeTeams
    }
  }
}
</script>

<style>

</style>
